import { VueConstructor } from "vue/types/vue";

// Components
import * as FormComponents from "@components/Form";

const ComponentsPlugin = {
  install: (Vue: VueConstructor) => {
    // NOTE: Global components using the '@Component' registration currently do not work
    //         for an unknown reason in production! The component appears to fail to
    //         register, leaving its functionality bare.

    // Form components
    Object.keys(FormComponents).forEach((key) => {
      // @ts-ignore
      const component = FormComponents[key];
      Vue.component(component.name, component);
    });
  },
};

export default ComponentsPlugin;
