import Vue from "vue";
import { Plugin as FragmentPlugin } from "vue-fragment";
import ShortKey from "vue-shortkey";

// Components
import App from "./App.vue";

// Styles
import "@fontsource/roboto";

// Utilities
import ComponentsPlugin from "@plugins/components";
import VuetifyPlugin from "@plugins/vuetify";
import "./registerServiceWorker";
import router from "./router";

Vue.config.productionTip = false;

Vue.use(ComponentsPlugin);
Vue.use(FragmentPlugin);
Vue.use(ShortKey);

new Vue({
  vuetify: VuetifyPlugin,
  router,
  render: (h) => h(App),
}).$mount("#app");
