// Utilities
import { randomItem } from "@utilities/list";

// Types
import { Coordinate } from "@typings/app";
import { FloodItTile } from "@typings/flood-it";

/**
 * Calculate maximum moves allowed based on board size and number of colors.
 *
 * @param   size   - Board size
 * @param   colors - Number of colors on board
 * @returns Returns a count of maximum moves allowed
 */
const calculateMaxMoves = (size: number, colors: number): number => {
  return Math.floor((30 * ((size + size) * colors)) / ((14 + 14) * 6));
};

/**
 * Flood-select a range of cells by colour
 *
 * @param   tiles          - Game tiles
 * @param   start          - Flood select start coordinates
 * @param   neighbourColor - Include tiles of this colour that neighbour base flood (optional)
 * @returns List of tiles selected by flood
 */
const floodSelect = (
  tiles: FloodItTile[][],
  start: Coordinate,
  neighbourColor: string | null = null,
): FloodItTile[] => {
  if (!tiles.length || !tiles[0].length) return [];
  const selectColor = tiles[start.y][start.x].color;

  const visited: boolean[][] = new Array(tiles.length)
    .fill(false)
    .map(() => new Array(tiles.length).fill(false));

  const oldQueue: Coordinate[] = [start];
  // Track neighbouring tiles that now match new colour
  const newQueue: Coordinate[] = [];

  let coordinates;
  const selectedTiles: FloodItTile[] = [];

  /**
   * Process a tile selection queue to find tiles that will be "directly"
   *   included in flood fill, or tiles that will be "indirectly" included
   *   because they match the new colour.
   * NOTE: Heavily adapted from: https://stackoverflow.com/a/22054295/4206438
   *
   * @param color - Selection test colour
   * @param queue - Queue of tiles for testing against old/new colour
   */
  const handleQueue = (color: string, queue: Coordinate[]) => {
    while (queue.length) {
      coordinates = queue.shift();
      if (!coordinates) continue;

      const { x, y } = coordinates;
      const tile = tiles[y][x];

      // Visited tiles have already been handled/selected (or ignored)
      if (visited[y][x]) continue;

      // Neighbouring tiles with the new colour should also be included in
      //   the select, but MUST be selected/queued/visited separately to avoid
      //   spilling over into their own neighbouring tiles of the old colour.
      if (color !== neighbourColor && tile.color === neighbourColor) {
        newQueue.push({ x, y });
        continue;
      }

      // All tiles that are checked/acted upon must be marked as visited!
      visited[y][x] = true;

      // Tiles not matching the current colour should be skipped, as it is
      //   easier to enqueue all tiles and then ignore invalid ones.
      if (tile.color !== color) continue;

      selectedTiles.push(tile);

      // Handle adding neighbouring tiles to the queue for checking. Tiles that
      //   do not match colour are ignored when taken from queue (easier).
      if (x > 0) {
        queue.push({ x: x - 1, y });
      }
      if (x + 1 < tiles.length) {
        queue.push({ x: x + 1, y });
      }
      if (y > 0) {
        queue.push({ x, y: y - 1 });
      }
      if (y + 1 < tiles[x].length) {
        queue.push({ x, y: y + 1 });
      }
    }
  };

  // Select tiles matching the original colour first
  handleQueue(selectColor, oldQueue);

  // Optionally select bordering tiles that will match the new colour.
  //   This allows including them in the animation (strange otherwise)
  if (!neighbourColor) return selectedTiles;
  handleQueue(neighbourColor, newQueue);

  return selectedTiles;
};

/**
 * Generate the board tiles
 *
 * @param   size   - Board size
 * @param   colors - Number of colors
 * @returns Board tiles
 */
const generateTiles = (size: number, colors: string[]): FloodItTile[][] => {
  const tiles: FloodItTile[][] = [];

  for (let y = 0; y < size; y++) {
    tiles[y] = [];

    for (let x = 0; x < size; x++) {
      tiles[y][x] = {
        color: randomItem(colors),
        coordinates: { x, y },
      };
    }
  }

  return tiles;
};

export { calculateMaxMoves, floodSelect, generateTiles };
