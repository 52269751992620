











































import { Component, Vue } from "vue-property-decorator";

// Components
import { GameOverMessage } from "@components/Games";
import MinesweeperActionBar from "./MinesweeperActionBar.vue";
import MinesweeperConfigDialog from "./MinesweeperConfigDialog.vue";
import MinesweeperStats from "./MinesweeperStats.vue";
import MinesweeperTile from "./MinesweeperTile.vue";

// Utilities
import {
  defaultGameConfig,
  loadMinesweeperConfig,
  saveMinesweeperConfig,
} from "./config";
import {
  calculateBombCount,
  countFlaggedTiles,
  countRevealedTiles,
  generateTiles,
  placeBombs,
  revealNeighbours,
} from "./utils";

// Types
import { Coordinate } from "@typings/app";
import { GameState, MinesweeperTileState } from "@typings/enums";
import {
  MinesweeperGameConfig,
  MinesweeperTile as MinesweeperTileType,
} from "@typings/minesweeper";

@Component({
  components: {
    GameOverMessage,
    MinesweeperActionBar,
    MinesweeperConfigDialog,
    MinesweeperStats,
    MinesweeperTile,
  },
})
export default class Minesweeper extends Vue {
  isConfigDialogShown = false;
  isDebug = false;

  /** Game tiles */
  tiles: MinesweeperTileType[][] = [[]];
  /** Previous game tiles (for restart) */
  previousTiles: MinesweeperTileType[][] = [[]];

  /** Game config */
  gameConfig: MinesweeperGameConfig = { ...defaultGameConfig };
  /** Game progress state */
  gameState: GameState = GameState.SETUP;

  /** Number of bombs placed */
  bombs = 0;
  /** Wait until after first turn to safely place bombs unless restarting board */
  bombsPlaced = false;
  /** Number of flags remaining */
  flagsRemaining = 0;
  /** Number of turns taken */
  turns = 0;
  tileCount = 0;

  playingTime = 0;
  playingTimeInterval: number | undefined = undefined;

  mounted() {
    this.gameConfig = loadMinesweeperConfig();

    this.startGame();
  }

  destroyed() {
    clearInterval(this.playingTimeInterval);
  }

  /**
   * Toggle whether a tile is flagged
   * @param tile - Selected tile
   */
  flagTile(tile: MinesweeperTileType) {
    // Flags can only be placed after the first tile is clicked
    if (this.turns <= 0) return;

    // Revealed tiles cannot be flagged
    if (tile.state === MinesweeperTileState.REVEALED) return;

    switch (tile.state) {
      case MinesweeperTileState.HIDDEN:
        // Prevent placing too many flags (ie. more than number of bombs)
        if (this.flagsRemaining <= 0) return;

        this.flagsRemaining--;
        tile.state = MinesweeperTileState.FLAGGED;
        break;
      case MinesweeperTileState.FLAGGED:
        this.flagsRemaining++;
        tile.state = MinesweeperTileState.QUESTIONED;
        break;
      case MinesweeperTileState.QUESTIONED:
        tile.state = MinesweeperTileState.HIDDEN;
        break;
    }
  }

  /**
   * Reveal whether a tile is a bomb
   * @param tile - Selected tile
   */
  revealTile(tile: MinesweeperTileType) {
    if (
      this.gameState !== GameState.SETUP &&
      this.gameState !== GameState.PLAYING
    )
      return;
    if (tile.state === MinesweeperTileState.REVEALED) return;

    // Flagged/questioned tiles cannot be revealed (must be unflagged)
    if (
      tile.state === MinesweeperTileState.FLAGGED ||
      tile.state === MinesweeperTileState.QUESTIONED
    )
      return;

    // First turn has several additional things to do (place bombs, start timer, etc)
    if (this.turns === 0) {
      this.performFirstTurn(tile.coordinates);
    }

    // Any change triggers the game state to begin playing
    if (this.gameState !== GameState.PLAYING) {
      this.gameState = GameState.PLAYING;
    }

    tile.state = MinesweeperTileState.REVEALED;
    this.turns++;

    // Revealing a bomb ends the game
    if (tile.bomb) {
      this.endGame(GameState.LOST);
      return;
    }

    // Revealing a tile with no neighbouring bombs should flood-reveal similar neighbours
    if (tile.bombCount === 0) {
      revealNeighbours(this.tiles, tile.coordinates.x, tile.coordinates.y);

      // Recalculate flags when revealing neighbours (could have revealed a flag)
      const placedFlags = countFlaggedTiles(this.tiles);
      this.flagsRemaining = this.bombs - placedFlags;
    }

    // Revealing the last non-bomb cells is a win condition
    const revealedTiles = countRevealedTiles(this.tiles);
    if (revealedTiles >= this.tileCount - this.bombs) {
      this.endGame(GameState.WON);
    }
  }

  /**
   * Complete game setup after the player's first turn
   *
   * @param tile - Revealed tile from first turn
   */
  performFirstTurn(tile: Coordinate) {
    // Place bombs after the player's first turn to ensure they cannot lose immediately,
    //   unless player is restarting with same board
    if (!this.bombsPlaced) {
      placeBombs(this.tiles, this.gameConfig, tile);
      this.bombsPlaced = true;

      // Store bomb locations for next game (if resetting)
      this.previousTiles = this.tiles.map((row) =>
        row.map((tile) => ({ ...tile })),
      );
    }

    // Start tracking how long player has been playing the game
    clearInterval(this.playingTimeInterval);
    this.playingTimeInterval = setInterval(() => {
      this.playingTime++;
    }, 1000);
  }

  /**
   * End the game
   *
   * @param state - End game state
   */
  endGame(state: GameState) {
    clearInterval(this.playingTimeInterval);

    // Bombs are always revealed at the end of the game
    for (let y = 0; y < this.tiles.length; y++) {
      for (let x = 0; x < this.tiles[y].length; x++) {
        if (!this.tiles[y][x].bomb) continue;

        this.tiles[y][x].state = MinesweeperTileState.REVEALED;
      }
    }

    // Prevent input until game over message is shown
    this.gameState = GameState.PAUSED;

    setTimeout(() => {
      this.gameState = state;
    }, 1000);
  }

  /**
   * Update the game config (and start new game)
   *
   * @param config - Game configuration object
   */
  updateConfig(config: MinesweeperGameConfig) {
    this.gameConfig = config;

    saveMinesweeperConfig(config);

    this.startGame();
  }

  /**
   * Reset the game to its initial state (without safe first turn)
   */
  resetGame() {
    this.startGame(true);
  }

  /**
   * Generate a new board and start the game
   *
   * @param restart - Whether to use previous board tiles/bombs
   */
  startGame(restart = false) {
    const { boardHeight, boardWidth } = this.gameConfig;

    this.tiles = generateTiles(this.gameConfig);

    // Restarting with same board should place bombs in same location
    const hasPreviousTiles =
      this.previousTiles.length && this.previousTiles[0].length;
    if (restart && hasPreviousTiles) {
      // Copy bombs from previous game
      this.tiles = this.previousTiles.map((row) =>
        row.map((tile) => ({ ...tile })),
      );
      this.bombsPlaced = true;
    } else {
      this.bombsPlaced = false;
    }

    // Reset general game state
    this.gameState = GameState.SETUP;
    this.bombs = calculateBombCount(this.gameConfig);
    this.flagsRemaining = this.bombs;
    this.turns = 0;
    this.tileCount = boardWidth * boardHeight;

    // Timer is started on the player's first turn
    clearInterval(this.playingTimeInterval);
    this.playingTime = 0;
  }
}
