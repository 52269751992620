/**
 * Get a random index
 *
 * @param   max - Maximum index range (exclusive)
 * @returns Random index
 */
const randomIndex = (max: number): number => {
  return Math.floor(Math.random() * max);
};

/**
 * Get a random item from a list
 *
 * @param   items - List of items
 * @returns Randomly selected item
 */
const randomItem = <T>(items: T[]): T => {
  const idx = randomIndex(items.length);
  return items[idx];
};

/**
 * Get a random item from a 2D list
 *
 * @param   items - 2D list of items
 * @returns Randomly selected item
 */
const random2DItem = <T>(items: T[][]): T => {
  const randomX = randomIndex(items.length);
  const xRow = items[randomX];

  const randomY = randomIndex(xRow.length);
  return xRow[randomY];
};

export { randomIndex, randomItem, random2DItem };
