// Utilities
import { random2DItem } from "@utilities/list";
import { minesweeperBombDifficulty } from "./config";

// Types
import { Coordinate } from "@typings/app";
import { MinesweeperGameConfig, MinesweeperTile } from "@typings/minesweeper";
import { MinesweeperTileState } from "@typings/enums";

/**
 * Calculate the number of bombs for a board
 * @param   config - Game config
 * @returns Number of bombs for a board
 */
const calculateBombCount = (config: MinesweeperGameConfig): number => {
  return Math.floor(
    config.boardHeight *
      config.boardWidth *
      minesweeperBombDifficulty[config.difficulty],
  );
};

/**
 * Count bombs in neighbouring tiles
 *
 * @param   tiles - Board tiles
 * @param   x     - X position
 * @param   y     - Y position
 * @returns Number of bombs in neighbouring tiles
 */
const countNeighbourBombs = (
  tiles: MinesweeperTile[][],
  x: number,
  y: number,
): number => {
  const tile = getTile(tiles, x, y);
  if (!tile) return 0;

  const neighbours = getNeighbouringTiles(tiles, x, y);
  return neighbours.reduce((accum, tile) => (tile.bomb ? accum + 1 : accum), 0);
};

/**
 * Count all flagged tiles
 *
 * @param   tiles - Board tiles
 * @returns Flagged tile count
 */
const countFlaggedTiles = (tiles: MinesweeperTile[][]): number => {
  let flaggedTiles = 0;

  for (let y = 0; y < tiles.length; y++) {
    for (let x = 0; x < tiles[y].length; x++) {
      const tile = tiles[y][x];
      if (tile.state !== MinesweeperTileState.FLAGGED) continue;
      flaggedTiles++;
    }
  }

  return flaggedTiles;
};

/**
 * Count all revealed tiles (excluding bombs)
 *
 * @param   tiles - Board tiles
 * @returns Revealed tile count
 */
const countRevealedTiles = (tiles: MinesweeperTile[][]): number => {
  let revealedTiles = 0;

  for (let y = 0; y < tiles.length; y++) {
    for (let x = 0; x < tiles[y].length; x++) {
      const tile = tiles[y][x];
      if (tile.state !== MinesweeperTileState.REVEALED) continue;
      revealedTiles++;
    }
  }

  return revealedTiles;
};

/**
 * Generate the Minesweeper board tiles
 *
 * @param   config - Game config
 * @returns Board tiles
 */
const generateTiles = (config: MinesweeperGameConfig): MinesweeperTile[][] => {
  const tiles: MinesweeperTile[][] = [];

  // Generate the board tiles
  for (let y = 0; y < config.boardHeight; y++) {
    tiles[y] = [];

    for (let x = 0; x < config.boardWidth; x++) {
      tiles[y][x] = {
        bombCount: 0,
        coordinates: { x, y },
        bomb: false,
        state: MinesweeperTileState.HIDDEN,
      };
    }
  }

  return tiles;
};

/**
 * Get neighbouring Minesweeper tiles
 *
 * @param   tiles            - Board tiles
 * @param   x                - X position
 * @param   y                - Y position
 * @param   includeDiagonals - Whether diagonal tiles are included
 * @returns Neighbouring tiles
 */
const getNeighbouringTiles = (
  tiles: MinesweeperTile[][],
  x: number,
  y: number,
  includeDiagonals = true,
): MinesweeperTile[] => {
  const neighbours: MinesweeperTile[] = [];

  for (let nx = x - 1; nx <= x + 1; nx++) {
    for (let ny = y - 1; ny <= y + 1; ny++) {
      if (nx === x && ny === y) continue;

      // Diagonal tiles can be excluded from results
      if (!includeDiagonals && nx !== x && ny !== y) continue;

      const tile = getTile(tiles, nx, ny);
      if (tile) neighbours.push(tile);
    }
  }

  return neighbours;
};

/**
 * Get a Minesweeper board tile
 *
 * @param   tiles - Board tiles
 * @param   x     - X position
 * @param   y     - Y position
 * @returns Selected tile
 */
const getTile = (
  tiles: MinesweeperTile[][],
  x: number,
  y: number,
): MinesweeperTile | null => {
  if (isNaN(y) || y < 0 || y >= tiles.length) return null;
  if (isNaN(x) || x < 0 || x >= tiles[0].length) return null;

  return tiles[y][x];
};

/**
 * Bombs are placed separately from generating board (allows skipping first turn)
 *
 * @param tiles  - Board tiles
 * @param config - Game config
 * @param start  - Starting tile
 */
const placeBombs = (
  tiles: MinesweeperTile[][],
  config: MinesweeperGameConfig,
  start?: Coordinate,
) => {
  // Add bombs to random tiles
  const maxBombs = calculateBombCount(config);
  let placedBombs = 0;

  while (placedBombs < maxBombs) {
    const randomTile = random2DItem(tiles);
    const { x, y } = randomTile.coordinates;

    // Optionall prevent placing bomb at start or around a tile with another bomb
    if (randomTile.bomb) continue;
    if (start && Math.abs(start.x - x) <= 1 && Math.abs(start.y - y) <= 1)
      continue;

    randomTile.bomb = true;
    placedBombs++;
  }

  // Calculate neighbouring bombs
  for (let y = 0; y < tiles.length; y++) {
    for (let x = 0; x < tiles[y].length; x++) {
      tiles[y][x].bombCount = countNeighbourBombs(tiles, x, y);
    }
  }
};

/**
 * Flood-reveal all neighbouring cells without neighbouring bombs (ends flood fill)
 *
 * @param   tiles - Game tiles
 * @param   x     - X position
 * @param   y     - Y position
 * @returns Number of revealed tiles
 */
const revealNeighbours = (
  tiles: MinesweeperTile[][],
  x: number,
  y: number,
): number => {
  const start: Coordinate = { x, y };

  const queue: Coordinate[] = [start];
  // NOTE: Use separate array (vs tile.state) to enable animating in different order!
  const revealed: boolean[][] = new Array(tiles.length)
    .fill(false)
    .map(() => new Array(tiles.length).fill(false));
  let revealedCount = 0;

  while (queue.length) {
    const coordinates = queue.shift();
    if (!coordinates) continue;
    const tile = tiles[coordinates.y][coordinates.x];

    // QUESTION: Should flagged cells be ignored (likely not)?
    if (revealed[coordinates.y][coordinates.x]) continue;

    tile.state = MinesweeperTileState.REVEALED;
    revealedCount++;
    revealed[coordinates.y][coordinates.x] = true;

    // Tiles with neighbouring bombs should not enqueue their neighbours
    if (tile.bombCount > 0) continue;

    const neighbours = getNeighbouringTiles(
      tiles,
      coordinates.x,
      coordinates.y,
    );
    neighbours.forEach((tile) => queue.push(tile.coordinates));
  }

  return revealedCount;
};

export {
  calculateBombCount,
  countFlaggedTiles,
  countNeighbourBombs,
  countRevealedTiles,
  generateTiles,
  getNeighbouringTiles,
  getTile,
  placeBombs,
  revealNeighbours,
};

/* eslint @typescript-eslint/no-use-before-define: off */
