
















































import { Component, Emit, Prop, Watch, Vue } from "vue-property-decorator";

// Utilities
import { FloodItGameConfig } from "@typings/flood-it";
import floodItConfig, { defaultGameConfig } from "./config";

@Component
export default class FloodItConfigDialog extends Vue {
  /** Game config form */
  configForm: FloodItGameConfig = {
    ...defaultGameConfig,
  };

  /** Select field items */
  items = {
    boardColors: floodItConfig.boardColorOptions,
    boardSizes: floodItConfig.boardSizeOptions,
  };

  /** Game config */
  @Prop({ required: true })
  config!: FloodItGameConfig;

  /** Whether the dialog is shown */
  @Prop({ required: true })
  value!: boolean;

  /**
   * Update the config when the parent prop changes
   */
  @Watch("config")
  onConfigChanged(val: FloodItGameConfig) {
    this.configForm = { ...val };
  }

  /**
   * Cancel handler
   */
  @Emit("input")
  @Emit("cancel")
  onCancel(): boolean {
    // Reset the form upon cancel
    this.configForm = { ...this.config };

    return false;
  }

  /**
   * Save handler
   */
  @Emit("save")
  onSave(): FloodItGameConfig {
    this.$emit("input", false);

    return this.configForm;
  }
}
