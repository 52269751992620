













import { mdiSkull, mdiTrophy } from "@mdi/js";
import { Component, Prop, Vue } from "vue-property-decorator";

// Types
import { GameState } from "@typings/enums";

interface GameStateMessage {
  /** Message icon */
  icon: string;
  /** Message text */
  message: string;
}

@Component({})
export default class FloodItBoardMessage extends Vue {
  /** Game progress state */
  @Prop({ required: true })
  gameState!: GameState;

  /** Game state info */
  get gameStateInfo(): GameStateMessage | null {
    switch (this.gameState) {
      case GameState.WON:
        return { icon: mdiTrophy, message: "You Won!" };
      case GameState.LOST:
        return { icon: mdiSkull, message: "You Lost!" };
      default:
        return null;
    }
  }
}
