










import { mdiSnake } from "@mdi/js";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SnakeStats extends Vue {
  /** Snake length */
  @Prop({ required: true })
  length!: number;

  icons = { mdiSnake };
}
