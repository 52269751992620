
















import { Component, Prop, Vue } from "vue-property-decorator";

// Components
import { GameAppBar } from "@components/Games";
import { TheAppFooter, TheAppHeader } from "@components/SingleUse";
import { Page } from "@components/Layout";

// Types
import { Game } from "@typings/app";

@Component({
  components: {
    GameAppBar,
    Page,
    TheAppFooter,
    TheAppHeader,
  },
})
export default class GamePage extends Vue {
  @Prop({ required: true })
  game!: Game;
}
