// Types
import { Game } from "@typings/app";

// Assets
import floodItIcon from "@assets/images/flood_it_icon.png";
import floodItImage from "@assets/images/flood_it_image.png";
import minesweeperIcon from "@assets/images/minesweeper_icon.png";
import minesweeperImage from "@assets/images/minesweeper_image.png";
import snakeIcon from "@assets/images/snake_icon.png";
import snakeImage from "@assets/images/snake_image.png";

// NOTE: Import all game routes dynamically and assign in router
const floodIt = () =>
  import(/* webpackChunkName: "floodIt" */ "@views/FloodIt.vue");
const minesweeper = () =>
  import(/* webpackChunkName: "minesweeper" */ "@views/Minesweeper.vue");
const snake = () => import(/* webpackChunkName: "snake" */ "@views/Snake.vue");

/** List of playable games */
const games: Record<string, Game> = {
  floodIt: {
    _component: floodIt,
    icon: floodItIcon,
    image: floodItImage,
    name: "Flood It",
    route: "/flood-it/",
  },
  minesweeper: {
    _component: minesweeper,
    icon: minesweeperIcon,
    image: minesweeperImage,
    name: "Minesweeper",
    route: "/minesweeper/",
  },
  snake: {
    _component: snake,
    icon: snakeIcon,
    image: snakeImage,
    name: "Snake",
    route: "/snake/",
  },
};

export default games;
