








import { Component, Prop, Vue } from "vue-property-decorator";
import colors from "vuetify/lib/util/colors";

/** Map turn text colors to a minimum game completion percent */
const turnColorPercentMap: Record<number, string> = {
  0: colors.teal.base,
  70: colors.amber.base,
  80: colors.orange.base,
  90: colors.red.base,
};

export { turnColorPercentMap };

@Component
export default class FloodItStats extends Vue {
  /** Maximum turns to win */
  @Prop({ required: true })
  maxTurns!: number;

  /** Turns taken so far */
  @Prop({ required: true })
  turns!: number;

  /** Turn color changes as game end approaches */
  get turnColor(): string {
    if (!this.turns || !this.maxTurns) return turnColorPercentMap[0];

    // Use turn completion percentage to determine status color (using a map)
    const percent = Math.floor((this.turns / this.maxTurns) * 100);
    const colorKeys = (Object.keys(turnColorPercentMap) as unknown) as number[];
    let matchingPercent = 0;
    for (let i = 0; i < colorKeys.length; i++) {
      if (percent >= colorKeys[i] && percent > matchingPercent) {
        matchingPercent = colorKeys[i];
      }
    }

    return turnColorPercentMap[matchingPercent];
  }
}
