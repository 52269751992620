











































import { Component, Vue } from "vue-property-decorator";

// Components
import FloodItActionBar from "./FloodItActionBar.vue";
import FloodItColorPicker from "./FloodItColorPicker.vue";
import FloodItConfigDialog from "./FloodItConfigDialog.vue";
import FloodItStats from "./FloodItStats.vue";
import { GameOverMessage } from "@components/Games";

// Utilities
import floodItConfig, {
  defaultGameConfig,
  loadFloodItConfig,
  saveFloodItConfig,
} from "./config";
import { calculateMaxMoves, floodSelect, generateTiles } from "./utils";

// Types
import { FloodItGameConfig, FloodItTile } from "@typings/flood-it";
import { GameState } from "@typings/enums";

const startCoordinates = { x: 0, y: 0 };

@Component({
  components: {
    FloodItActionBar,
    FloodItColorPicker,
    FloodItConfigDialog,
    FloodItStats,
    GameOverMessage,
  },
})
export default class FloodIt extends Vue {
  /** Game configuration */
  gameConfig: FloodItGameConfig = defaultGameConfig;
  /** Game progress state */
  gameState: GameState = GameState.SETUP;
  isConfigDialogShown = false;
  tileSize = floodItConfig.tileSize;

  /** Game tiles */
  tiles: FloodItTile[][] = [[]];
  /** Previous game tiles (for restart) */
  previousTiles: FloodItTile[][] = [[]];

  /** Maximum number of turns */
  maxTurns = 0;
  /** Number of turns taken */
  turns = 0;

  /** Number of solved tiles */
  solvedCount = 0;
  /** Number of tiles */
  tileCount = 0;

  /** Colours are limited by game configuration */
  get availableColors(): string[] {
    return floodItConfig.colors.slice(0, this.gameConfig.boardColors);
  }

  /** Whether the color tools are disabled */
  get areColorsDisabled(): boolean {
    return (
      this.gameState !== GameState.PLAYING && this.gameState !== GameState.SETUP
    );
  }

  /** Currently solved colour */
  get currentColor(): string | null {
    if (!this.tiles[0]) return null;

    return this.tiles[0][0].color;
  }

  mounted() {
    this.gameConfig = loadFloodItConfig();

    this.startGame();
  }

  /**
   * Perform a turn by flooding board colour
   *
   * @param color - New colour to flood board with
   */
  performTurn(color: string) {
    // Prevent filling with current colour (waste of turn)
    if (!this.currentColor || color === this.currentColor) return;

    const selectedTiles: FloodItTile[] = floodSelect(
      this.tiles,
      startCoordinates,
      color,
    );

    // Prevent filling with colour that does not advance solve progress
    if (selectedTiles.length <= this.solvedCount) return;

    // Any change triggers the game state to begin playing
    if (this.gameState !== GameState.PLAYING) {
      this.gameState = GameState.PLAYING;
    }

    // TODO: Animate?
    selectedTiles.forEach((tile) => {
      const { x, y } = tile.coordinates;

      this.changeCell(x, y, color);
    });

    this.solvedCount = selectedTiles.length;

    this.turns++;

    // Check the game stats (win/loss conditions etc)
    this.checkGameState();
  }

  /**
   * Change the color of a cell (with support for reactiveness)
   *
   * @param x     - Cell x position
   * @param y     - Cell y position
   * @param color - New color
   */
  changeCell(x: number, y: number, color: string) {
    if (!this.tiles) return;

    // NOTE: Additional work is required to get around Vue reactivity issues
    //         with directly updating array values.
    const newRow = this.tiles[y].slice(0);
    newRow[x] = { ...newRow[x], color };
    this.$set(this.tiles, y, newRow);
  }

  /**
   * Check the game win/lose conditions
   */
  checkGameState() {
    if (!this.tiles) return;

    if (this.solvedCount >= this.tileCount) {
      this.gameState = GameState.WON;
      return;
    }

    // Must check game loss after in case player won on last turn!
    if (this.turns >= this.maxTurns) {
      this.gameState = GameState.LOST;
      return;
    }
  }

  /**
   * Update the game config (and start new game)
   *
   * @param config - Game configuration object
   */
  updateConfig(config: FloodItGameConfig) {
    this.gameConfig = config;

    saveFloodItConfig(config);

    this.startGame();
  }

  /**
   * Restart the game with the initial layout
   */
  resetGame() {
    this.startGame(true);
  }

  /**
   * Generate a new board and start the game
   *
   * @param restart - Whether to use previous board tiles/bombs
   */
  startGame(restart = false) {
    const { boardColors, boardSize } = this.gameConfig;

    this.tiles = generateTiles(boardSize, this.availableColors);

    // Restarting should use the previous board initial layout
    const hasPreviousTiles =
      this.previousTiles.length && this.previousTiles[0].length;
    if (restart && hasPreviousTiles) {
      this.tiles = this.previousTiles.map((row) =>
        row.map((tile) => ({ ...tile })),
      );
    } else {
      this.previousTiles = this.tiles.map((row) =>
        row.map((tile) => ({ ...tile })),
      );
    }

    // Reset general game state
    this.gameState = GameState.SETUP;
    this.turns = 0;
    this.maxTurns = calculateMaxMoves(boardSize, boardColors);
    this.solvedCount = floodSelect(this.tiles, startCoordinates).length;
    this.tileCount = boardSize * boardSize;
  }
}
