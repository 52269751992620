// Utilities
import { random2DItem } from "@utilities/list";
import snakeConfig from "./config";

// Types
import { SnakeGameConfig, SnakeTile } from "@typings/snake";
import { SnakeDirection, SnakeTileState } from "@typings/enums";

/**
 * Generate the Snake board tiles
 *
 * @param   config - Game config
 * @returns Board tiles
 */
const generateTiles = (config: SnakeGameConfig): SnakeTile[][] => {
  const tiles: SnakeTile[][] = [];

  // Generate the board tiles
  for (let y = 0; y < config.boardHeight; y++) {
    tiles[y] = [];

    for (let x = 0; x < config.boardWidth; x++) {
      tiles[y][x] = {
        coordinates: { x, y },
        state: SnakeTileState.OPEN,
      };
    }
  }

  return tiles;
};

/**
 * Get a Snake board tile
 *
 * @param   tiles - Board tiles
 * @param   x     - X position
 * @param   y     - Y position
 * @returns Selected tile
 */
const getTile = (
  tiles: SnakeTile[][],
  x: number,
  y: number,
): SnakeTile | null => {
  if (isNaN(y) || y < 0 || y >= tiles.length) return null;
  if (isNaN(x) || x < 0 || x >= tiles[0].length) return null;

  return tiles[y][x];
};

/**
 * Get the next Snake board tile in a direction
 *
 * @param   tiles     - Board tiles
 * @param   direction - Movement direction
 * @param   x         - X position
 * @param   y         - Y position
 * @returns Next tile in direction
 */
const getNextTile = (
  tiles: SnakeTile[][],
  direction: SnakeDirection,
  x: number,
  y: number,
): SnakeTile | null => {
  let tile: SnakeTile | null = null;

  if (direction === SnakeDirection.UP) {
    tile = getTile(tiles, x, y - 1);
  } else if (direction === SnakeDirection.RIGHT) {
    tile = getTile(tiles, x + 1, y);
  } else if (direction === SnakeDirection.DOWN) {
    tile = getTile(tiles, x, y + 1);
  } else if (direction === SnakeDirection.LEFT) {
    tile = getTile(tiles, x - 1, y);
  }

  return tile;
};

/**
 * Food is placed randomly when snake eats previous food
 *
 * @param tiles  - Board tiles
 */
const placeFood = (tiles: SnakeTile[][]) => {
  let isPlaced = false;

  while (!isPlaced) {
    const randomTile = random2DItem(tiles);

    // Food can only be placed on open tiles
    if (randomTile.state !== SnakeTileState.OPEN) continue;

    randomTile.state = SnakeTileState.FOOD;
    isPlaced = true;
  }
};

/**
 * Place the initial snake on the board
 *
 * @param   tiles  - Board tiles
 * @param   config - Snake game config
 * @returns Snake segment tiles
 */
const placeSnake = (tiles: SnakeTile[][], config: SnakeGameConfig) => {
  const segments: SnakeTile[] = [];

  const headTile: SnakeTile = getTile(
    tiles,
    Math.floor(config.boardWidth / 2),
    snakeConfig.startingSize - 1,
  ) as SnakeTile;

  headTile.state = SnakeTileState.SNAKE;
  segments.push(headTile);

  // TODO: Refactor if possible into single loop
  while (segments.length < snakeConfig.startingSize) {
    const previousTile = segments[segments.length - 1];

    const nextTile = getNextTile(
      tiles,
      SnakeDirection.UP,
      previousTile.coordinates.x,
      previousTile.coordinates.y,
    );
    if (!nextTile) break;

    nextTile.state = SnakeTileState.SNAKE;
    segments.push(nextTile);
  }

  return segments;
};

export { generateTiles, getTile, getNextTile, placeFood, placeSnake };

/* eslint @typescript-eslint/no-use-before-define: off */
