<template>
  <v-slider
    dense
    filled
    COMMENT2="Bind these last to enable overriding"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
/**
 * Vuetify Slider wrapper
 */
export default {
  name: "Slider",
};
</script>
