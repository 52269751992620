// Utilities
import { version } from "../package.json";

/** App configuration */
interface Config {
  /** Git repository url */
  gitUrl: string;
  /** App version */
  version: string;
}

/** App configuration */
const config: Config = {
  gitUrl: process.env.VUE_APP_GIT_URL,
  version: version,
};

export default config;
