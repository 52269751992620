

















import { mdiClock, mdiFlag } from "@mdi/js";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class MinesweeperStats extends Vue {
  /** Flags remaining to place */
  @Prop({ required: true })
  flags!: number;

  /** Turns taken so far */
  @Prop({ required: true })
  turns!: number;

  /** Time spent in game */
  @Prop({ required: true })
  time!: number;

  icons = { mdiClock, mdiFlag };

  /** Formatted playing time */
  get formattedTime(): string {
    if (this.time === 0) return `--:--`;

    const minutes = Math.floor(this.time / 60);
    const seconds = this.time % 60;

    return `${this.padNumber(minutes, 2)}:${this.padNumber(seconds, 2)}`;
  }

  /**
   * Pad a number with zeros
   *
   * @param   number - Number to pad
   * @param   size   - Target amount of digits
   * @returns Padded number string
   */
  padNumber(number: number, size: number) {
    return new Array(size)
      .concat([Math.abs(number)])
      .join("0")
      .slice(-size);
  }
}
