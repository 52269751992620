/** Game progress state */
export enum GameState {
  /** Game is in setup phase */
  SETUP = "SETUP",
  /** Game is in progress */
  PLAYING = "PLAYING",
  /** Game is paused */
  PAUSED = "PAUSED",
  /** Game is a draw */
  DRAW = "DRAW",
  /** Game has been won */
  WON = "WON",
  /** Game has been lost */
  LOST = "LOST",
}

/** Minesweeper game difficulty */
export enum MinesweeperDifficulty {
  /** Beginner difficulty */
  BEGINNER = "BEGINNER",
  /** Intermediate difficulty */
  INTERMEDIATE = "INTERMEDIATE",
  /** Expert difficulty */
  EXPERT = "EXPERT",
}

/** Minesweeper tile state */
export enum MinesweeperTileState {
  /** Tile is flagged */
  FLAGGED = "FLAGGED",
  /** Tile is questions */
  QUESTIONED = "QUESTIONED",
  /** Tile is revealed */
  REVEALED = "REVEALED",
  /** Tile is hidden and unflagged */
  HIDDEN = "HIDDEN",
}

/** Snake movement direction */
export enum SnakeDirection {
  /** Up direction */
  UP = "UP",
  /** Right direction */
  RIGHT = "RIGHT",
  /** Down direction */
  DOWN = "DOWN",
  /** Left direction */
  LEFT = "LEFT",
}

/** Snake tile state */
export enum SnakeTileState {
  /** Tile has food */
  FOOD = "FOOD",
  /** Tile is open */
  OPEN = "OPEN",
  /** Tile is part os snake */
  SNAKE = "SNAKE",
}
