



















import { mdiChevronRight, mdiHome } from "@mdi/js";
import { Component, Prop, Vue } from "vue-property-decorator";

// Types
import { Game } from "@typings/app";

@Component
export default class GameAppBar extends Vue {
  @Prop({ required: true })
  game!: Game;

  icons = { mdiChevronRight, mdiHome };
}
