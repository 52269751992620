// Types
import { FloodItConfig, FloodItGameConfig } from "@typings/flood-it";

const colors: string[] = [
  "#e54d38",
  "#f79e10",
  "#f3cc26",
  "#30ca72",
  "#2f9bdc",
  "#b763ca",
  "#f37abf",
];
export const localStorageKey = "floodItConfig";

// Populate list for setting number of colors on board
const minColors = 3;
const boardColorOptions: number[] = Array(colors.length - minColors + 1)
  .fill(0)
  .map((_, i) => i + minColors);

/** Board configuration settings */
const floodItConfig: FloodItConfig = {
  colors,
  boardColorOptions,
  boardSizeOptions: [8, 10, 12, 14, 16, 18, 20, 22, 24],
  tileSize: 25,
};

/** Default game configuration */
export const defaultGameConfig: FloodItGameConfig = {
  boardColors: 6,
  boardSize: 16,
};

/**
 * Load the user's flood it game configuration
 * @returns User's flood it game configuration
 */
export const loadFloodItConfig = (): FloodItGameConfig => {
  const gameConfigString = localStorage.getItem(localStorageKey);
  if (!gameConfigString) return defaultGameConfig;

  const gameConfig: FloodItGameConfig = JSON.parse(gameConfigString);
  const { boardColors, boardSize } = gameConfig;

  return {
    boardColors: floodItConfig.boardColorOptions.includes(boardColors)
      ? boardColors
      : defaultGameConfig.boardColors,
    boardSize: floodItConfig.boardSizeOptions.includes(boardSize)
      ? boardSize
      : defaultGameConfig.boardSize,
  };
};

/**
 * Load the user's flood it game configuration
 * @param config - User's flood it game configuration
 */
export const saveFloodItConfig = (config: FloodItGameConfig) => {
  localStorage.setItem(localStorageKey, JSON.stringify(config));
};

export default floodItConfig;
