// Types
import { SnakeDirection } from "@typings/enums";
import { SnakeConfig, SnakeGameConfig } from "@typings/snake";

export const localStorageKey = "snakeConfig";

/** Snake overall config */
const snakeConfig: SnakeConfig = {
  boardSizeOptions: [10, 15, 20, 25, 30, 35, 40],
  movementSpeed: 0.18,
  startingDirection: SnakeDirection.DOWN,
  startingSize: 3,
  tileSize: 30,
};

/** Snake default per-game configuration */
export const defaultGameConfig: SnakeGameConfig = {
  boardHeight: 20,
  boardWidth: 20,
};

/** Snake key bindings */
export const snakeKeybindings: Record<SnakeDirection, string[]> = {
  [SnakeDirection.UP]: ["arrowup"],
  [SnakeDirection.DOWN]: ["arrowdown"],
  [SnakeDirection.LEFT]: ["arrowleft"],
  [SnakeDirection.RIGHT]: ["arrowright"],
};

/**
 * Load the user's snake game configuration
 * @returns User's snake game configuration
 */
export const loadSnakeConfig = (): SnakeGameConfig => {
  const gameConfigString = localStorage.getItem(localStorageKey);
  if (!gameConfigString) return defaultGameConfig;

  const gameConfig: SnakeGameConfig = JSON.parse(gameConfigString);
  const { boardHeight, boardWidth } = gameConfig;

  return {
    boardHeight: snakeConfig.boardSizeOptions.includes(boardHeight)
      ? boardHeight
      : defaultGameConfig.boardHeight,
    boardWidth: snakeConfig.boardSizeOptions.includes(boardWidth)
      ? boardWidth
      : defaultGameConfig.boardWidth,
  };
};

/**
 * Load the user's snake game configuration
 * @param config - User's snake game configuration
 */
export const saveSnakeConfig = (config: SnakeGameConfig) => {
  localStorage.setItem(localStorageKey, JSON.stringify(config));
};

export default snakeConfig;
