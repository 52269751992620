

































import { mdiPlay } from "@mdi/js";
import { Component, Vue } from "vue-property-decorator";

// Components
import { TheAppFooter, TheAppHeader } from "@components/SingleUse";
import { GameCard } from "@components/Games";
import { Page } from "@components/Layout";

// Utilities
import games from "@/games";

// Types
import { Game } from "@typings/app";

@Component({
  components: {
    GameCard,
    Page,
    TheAppFooter,
    TheAppHeader,
  },
})
export default class Home extends Vue {
  icons = { mdiPlay };
  selectedGame: Game | null = null;

  get games(): Game[] {
    return Object.values(games);
  }

  /**
   * Select a game to be played
   * @param game - Selected game
   */
  selectGame(game: Game) {
    this.selectedGame = this.selectedGame === game ? null : game;
  }

  /**
   * Play the currently selected game
   */
  play() {
    if (!this.selectedGame) return;

    this.$router.push(this.selectedGame.route);
  }
}
