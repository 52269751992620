













import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class FloodItColorPicker extends Vue {
  /** List of available game colors */
  @Prop({ required: true })
  colors!: string[];

  /** Whether the colors are disabled */
  @Prop({ default: false })
  disabled!: boolean;

  /**
   * Change the currently selected color
   * @param color - Selected color
   */
  @Emit("change")
  changeColor(color: string) {
    return color;
  }
}
