







































import { mdiBomb, mdiFlag, mdiHelp } from "@mdi/js";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import colors from "vuetify/lib/util/colors";

// Utilities
import minesweeperConfig from "./config";

// Types
import { MinesweeperTileState } from "@typings/enums";
import { MinesweeperTile as MinesweeperTileType } from "@typings/minesweeper";

const bombColorMap: Record<number, string> = {
  1: colors.blue.darken3,
  2: colors.green.darken3,
  3: colors.amber.darken3,
  4: colors.deepOrange.darken3,
  5: colors.red.darken3,
  6: colors.red.darken3,
  7: colors.red.darken3,
  8: colors.red.darken3,
};

@Component
export default class MinesweeperTile extends Vue {
  /** Whether game is in debug mode */
  @Prop({ default: false })
  debug!: boolean;

  /** Minesweeper tile */
  @Prop({ required: true })
  tile!: MinesweeperTileType;

  icons = { mdiBomb, mdiFlag, mdiHelp };
  MinesweeperTileState = MinesweeperTileState;
  tileSize = minesweeperConfig.tileSize;

  /** Colour of bomb count number */
  get bombCountColor(): string {
    return bombColorMap[this.tile.bombCount];
  }

  /** Whether the tile is flagged */
  get isFlagged(): boolean {
    return this.tile.state === MinesweeperTileState.FLAGGED;
  }

  /** Whether the tile is questioned */
  get isQuestioned(): boolean {
    return this.tile.state === MinesweeperTileState.QUESTIONED;
  }

  /** Whether the tile is revealed */
  get isRevealed(): boolean {
    return this.tile.state === MinesweeperTileState.REVEALED;
  }

  /** Reveal a tile */
  @Emit("reveal")
  revealTile() {
    return this.tile;
  }

  /** Flag a tile */
  @Emit("flag")
  flagTile() {
    return this.tile;
  }
}
