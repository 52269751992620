

























import { mdiGit } from "@mdi/js";
import { Component, Vue } from "vue-property-decorator";

// Utilities
import config from "@config";

@Component
export default class TheAppHeader extends Vue {
  icons = { mdiGit };
  gitUrl = config.gitUrl;
}
