


































































import { Component, Emit, Prop, Watch, Vue } from "vue-property-decorator";

// Utilities
import minesweeperConfig, {
  defaultGameConfig,
  getDifficultyFromIndex,
  getIndexFromDifficulty,
} from "./config";

// Types
import { MinesweeperDifficulty } from "@typings/enums";
import { MinesweeperGameConfig } from "@typings/minesweeper";

/** Slider field for difficulty Enum requires tracking slider index in form */
interface MinesweeperGameConfigForm extends MinesweeperGameConfig {
  difficultyIndex: number;
}

// Several calculations needed to convert from difficulty Enum to slider number/index
const defaultGameConfigForm: MinesweeperGameConfigForm = {
  ...defaultGameConfig,
  difficultyIndex: getIndexFromDifficulty(defaultGameConfig.difficulty),
};

@Component
export default class MinesweeperConfigDialog extends Vue {
  /** Game config form */
  configForm: MinesweeperGameConfigForm = {
    ...defaultGameConfigForm,
  };

  /** Select field items */
  items = {
    boardSizes: minesweeperConfig.boardSizeOptions,
    difficulties: Object.keys(MinesweeperDifficulty).map(
      (key) => key.substring(0, 1) + key.slice(1).toLowerCase(),
    ),
  };

  /** Game config */
  @Prop({ required: true })
  config!: MinesweeperGameConfig;

  /** Whether the dialog is shown */
  @Prop({ required: true })
  value!: boolean;

  /**
   * Convert difficulty slider index to enum value
   * @param index - Slider value
   */
  updateDifficulty(index: number) {
    this.configForm.difficulty = getDifficultyFromIndex(index);
  }

  /**
   * Update the config when the parent prop changes
   */
  @Watch("config")
  onConfigChanged(val: MinesweeperGameConfig) {
    this.configForm = {
      ...val,
      difficultyIndex: getIndexFromDifficulty(val.difficulty),
    };
  }

  /**
   * Cancel handler
   */
  @Emit("input")
  @Emit("cancel")
  onCancel(): boolean {
    // Reset the form upon cancel
    this.configForm = {
      ...this.config,
      difficultyIndex: getIndexFromDifficulty(this.config.difficulty),
    };

    return false;
  }

  /**
   * Save handler
   */
  @Emit("save")
  onSave(): MinesweeperGameConfig {
    this.$emit("input", false);

    return this.configForm;
  }
}
