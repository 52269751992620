













import { Component, Prop, Vue } from "vue-property-decorator";

// Utilities
import snakeConfig from "./config";

// Types
import { SnakeTileState } from "@typings/enums";
import { SnakeTile as SnakeTileType } from "@typings/snake";

@Component
export default class MinesweeperTile extends Vue {
  /** Snake tile */
  @Prop({ required: true })
  tile!: SnakeTileType;

  tileSize = snakeConfig.tileSize;

  /** Whether tile is food for snake */
  get isFood() {
    return this.tile.state === SnakeTileState.FOOD;
  }

  /** Whether tile is open */
  get isOpen() {
    return this.tile.state === SnakeTileState.OPEN;
  }

  /** Whether tile is part of snake */
  get isSnake() {
    return this.tile.state === SnakeTileState.SNAKE;
  }
}
