import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

// Utilities
import games from "./games";

// Views
import Home from "@views/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
];

/**
 * Create routes for each game (includes lazy-loading bundle files!)
 */
const makeLazyGameRoutes = () => {
  Object.keys(games).forEach((key) => {
    const game = games[key];

    routes.push({
      component: game._component,
      path: game.route,
      name: key,
    });
  });
};

makeLazyGameRoutes();

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
