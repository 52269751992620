
















































import { Component, Emit, Prop, Watch, Vue } from "vue-property-decorator";

// Utilities
import snakeConfig, { defaultGameConfig } from "./config";

// Types
import { SnakeGameConfig } from "@typings/snake";

const defaultGameConfigForm: SnakeGameConfig = {
  ...defaultGameConfig,
};

@Component
export default class SnakeConfigDialog extends Vue {
  /** Game config form */
  configForm: SnakeGameConfig = {
    ...defaultGameConfigForm,
  };

  /** Select field items */
  items = {
    boardSizes: snakeConfig.boardSizeOptions,
  };

  /** Game config */
  @Prop({ required: true })
  config!: SnakeGameConfig;

  /** Whether the dialog is shown */
  @Prop({ required: true })
  value!: boolean;

  /**
   * Update the config when the parent prop changes
   */
  @Watch("config")
  onConfigChanged(val: SnakeGameConfig) {
    this.configForm = {
      ...val,
    };
  }

  /**
   * Cancel handler
   */
  @Emit("input")
  @Emit("cancel")
  onCancel(): boolean {
    // Reset the form upon cancel
    this.configForm = {
      ...this.config,
    };

    return false;
  }

  /**
   * Save handler
   */
  @Emit("save")
  onSave(): SnakeGameConfig {
    this.$emit("input", false);

    return this.configForm;
  }
}
