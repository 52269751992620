





















































import { mdiCog, mdiRefresh } from "@mdi/js";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

// Components
import { ConfirmDialog } from "@components/Dialogs";

// Types
import { GameState } from "@typings/enums";

@Component({
  components: {
    ConfirmDialog,
  },
})
export default class FloodItActionBar extends Vue {
  /** Game progress state */
  @Prop({ required: true })
  gameState!: GameState;

  GameState = GameState;
  icons = { mdiCog, mdiRefresh };
  isResetPromptShown = false;

  @Emit("generate")
  generateGame() {
    // Do nothing
  }

  @Emit("configure")
  configureGame() {
    // Do nothing
  }

  @Emit("reset")
  resetGame() {
    // Do nothing
  }
}
