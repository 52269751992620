



























import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class ConfirmDialog extends Vue {
  /** Cancel button text */
  @Prop({ default: "No " })
  cancelText!: string;

  /** Confirm button text */
  @Prop({ default: "Yes " })
  confirmText!: string;

  /** Whether the confirmation dialog is disabled */
  @Prop({ default: false })
  disabled!: boolean;

  /** Dialog title */
  @Prop({ required: true })
  title!: string;

  /** Whether the dialog is shown */
  @Prop({ required: true })
  value!: boolean;

  /**
   * Cancel handler
   */
  @Emit("input")
  @Emit("cancel")
  onCancel() {
    return false;
  }

  /**
   * Confirm handler
   */
  @Emit("input")
  @Emit("confirm")
  onConfirm() {
    return false;
  }
}
