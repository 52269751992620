






import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Page extends Vue {
  /** Background colour */
  @Prop({ default: "" })
  color!: string;
}
