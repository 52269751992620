


























import { mdiPlay } from "@mdi/js";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

// Types
import { Game } from "@typings/app";

@Component
export default class GameCard extends Vue {
  /** Game descriptor */
  @Prop({ required: true })
  game!: Game;

  /** Whether game is selected */
  @Prop({ default: false })
  selected!: boolean;

  icons = { mdiPlay };

  /** Select the game */
  @Emit("select")
  select(): Game {
    return this.game;
  }
}
