



































import { Component, Vue } from "vue-property-decorator";

// Components
import { GameOverMessage } from "@components/Games";
import SnakeActionBar from "./SnakeActionBar.vue";
import SnakeConfigDialog from "./SnakeConfigDialog.vue";
import SnakeStats from "./SnakeStats.vue";
import SnakeTile from "./SnakeTile.vue";

// Utilities
import snakeConfig, {
  defaultGameConfig,
  loadSnakeConfig,
  saveSnakeConfig,
  snakeKeybindings,
} from "./config";
import { generateTiles, getNextTile, placeFood, placeSnake } from "./utils";

// Types
import { GameState, SnakeDirection, SnakeTileState } from "@typings/enums";
import { SnakeGameConfig, SnakeTile as SnakeTileType } from "@typings/snake";

@Component({
  components: {
    GameOverMessage,
    SnakeActionBar,
    SnakeConfigDialog,
    SnakeStats,
    SnakeTile,
  },
})
export default class Snake extends Vue {
  keyBindings = snakeKeybindings;
  isConfigDialogShown = false;

  /** Game tiles */
  tiles: SnakeTileType[][] = [[]];
  /** Snake segments */
  segments: SnakeTileType[] = [];

  /** Game config */
  gameConfig: SnakeGameConfig = { ...defaultGameConfig };
  /** Game progress state */
  gameState: GameState = GameState.SETUP;

  /** Movement direction */
  direction = snakeConfig.startingDirection;
  /** Whether direction has changed since last movement (limit) */
  directionChanged = false;
  /** Snake movement inverval */
  movementInterval: number | undefined = undefined;
  /** Time before game start (seconds) */
  startGameTimeout = 1;

  mounted() {
    this.gameConfig = loadSnakeConfig();

    this.generateBoard();
  }

  destroyed() {
    clearInterval(this.movementInterval);
  }

  /**
   * Change snake direction
   *
   * @param event - Shortkey event
   */
  changeDirection(event: any) {
    // Direction can only be changed once per snake movement
    if (this.directionChanged) return;

    // Validate that the direction key is a valid direction enum value
    const directionString: string = event.srcKey;
    if (!Object.keys(SnakeDirection).includes(directionString)) return;
    const direction = directionString as SnakeDirection;
    const previousDirection = this.direction;

    // Prevent snake from doubling back on itself directly
    const { DOWN, LEFT, RIGHT, UP } = SnakeDirection;
    if (direction === UP && previousDirection !== DOWN)
      this.direction = SnakeDirection.UP;
    if (direction === DOWN && previousDirection !== UP)
      this.direction = SnakeDirection.DOWN;
    if (direction === LEFT && previousDirection !== RIGHT)
      this.direction = SnakeDirection.LEFT;
    if (direction === RIGHT && previousDirection !== LEFT)
      this.direction = SnakeDirection.RIGHT;

    // Only indicate changed direction if a change was made
    if (previousDirection !== this.direction) {
      this.directionChanged = true;
    }
  }

  /**
   * Move the snake (user can change direction)
   */
  moveSnake() {
    if (!this.segments.length) return;
    if (this.gameState !== GameState.PLAYING) return;

    const headTile: SnakeTileType = this.segments[0];
    const nextTile: SnakeTileType | null = getNextTile(
      this.tiles,
      this.direction,
      headTile.coordinates.x,
      headTile.coordinates.y,
    );

    // Running off the map or into another part of the snake is game over
    if (!nextTile || nextTile.state === SnakeTileState.SNAKE) {
      this.endGame(GameState.LOST);
      return;
    }

    // Picking up food increases the snake length
    const pickedUpFood = nextTile.state == SnakeTileState.FOOD;

    // Do not remove the snake "tail" segment if food was picked up (simulates growth)
    if (!pickedUpFood) {
      const oldTile = this.segments.pop();
      if (oldTile) oldTile.state = SnakeTileState.OPEN;
    }

    this.segments.unshift(nextTile);
    nextTile.state = SnakeTileState.SNAKE;

    this.directionChanged = false;

    // Wait until snake changes are complete to place next food
    if (pickedUpFood) {
      placeFood(this.tiles);
    }
  }

  /**
   * Update the game config (and start new game)
   *
   * @param config - Game configuration object
   */
  updateConfig(config: SnakeGameConfig) {
    this.gameConfig = config;

    saveSnakeConfig(config);

    this.generateBoard();
  }

  /**
   * End the game
   *
   * @param state - End game state
   */
  endGame(state: GameState) {
    clearInterval(this.movementInterval);

    // NOTE: There is literally no way the player will ever win the game...
    //         this function could pretty much be called "loseGame"...

    this.gameState = state;
  }

  /**
   * Generate a board
   */
  generateBoard() {
    this.tiles = generateTiles(this.gameConfig);
  }

  /**
   * Start the game
   */
  startGame() {
    this.generateBoard();

    this.gameState = GameState.PAUSED;
    this.direction = snakeConfig.startingDirection;

    // Wait for a brief moment before placing and moving the snake
    setTimeout(() => {
      this.segments = placeSnake(this.tiles, this.gameConfig);
      placeFood(this.tiles);

      this.gameState = GameState.PLAYING;

      // Snake moves on a repeated interval
      clearInterval(this.movementInterval);
      this.movementInterval = setInterval(() => {
        this.moveSnake();
      }, snakeConfig.movementSpeed * 1000);
    }, this.startGameTimeout * 1000);
  }
}
