<template>
  <v-select
    :menu-props="{ offsetY: false }"
    dense
    filled
    COMMENT2="Bind these last to enable overriding"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
/**
 * Vuetify Select wrapper
 */
export default {
  name: "SelectField",
};
</script>
