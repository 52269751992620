// Types
import { MinesweeperConfig, MinesweeperGameConfig } from "@typings/minesweeper";
import { MinesweeperDifficulty } from "@typings/enums";

export const localStorageKey = "minesweeperConfig";

/** Minesweeper overall config */
const minesweeperConfig: MinesweeperConfig = {
  // Board sizes from 8 to 40 in multiples of 2
  boardSizeOptions: Array.from(Array(16), (v, k) => 10 + 2 * k),
  tileSize: 35,
};

/** Minesweeper difficulty bomb placement map  */
export const minesweeperBombDifficulty: Record<
  MinesweeperDifficulty,
  number
> = {
  [MinesweeperDifficulty.BEGINNER]: 0.11,
  [MinesweeperDifficulty.INTERMEDIATE]: 0.16,
  [MinesweeperDifficulty.EXPERT]: 0.2,
};

/**
 * Convert a difficulty enum to an index for difficulty form slider
 * @param   difficulty - Minesweeper difficulty enum value
 * @returns Index value for slider
 */
export const getIndexFromDifficulty = (
  difficulty: MinesweeperDifficulty,
): number => {
  return Object.keys(MinesweeperDifficulty).indexOf(difficulty);
};

/**
 * Convert a difficulty slider value to its matching enum value
 * @param   index - Minesweeper difficulty form slider value
 * @returns Minesweeper difficulty enum value
 */
export const getDifficultyFromIndex = (
  index: number,
): MinesweeperDifficulty => {
  return Object.keys(MinesweeperDifficulty)[index] as MinesweeperDifficulty;
};

/** Minesweeper default per-game configuration */
export const defaultGameConfig: MinesweeperGameConfig = {
  difficulty: MinesweeperDifficulty.BEGINNER,
  boardHeight: 16,
  boardWidth: 16,
};

/**
 * Load the user's minesweeper game configuration
 * @returns User's minesweeper game configuration
 */
export const loadMinesweeperConfig = (): MinesweeperGameConfig => {
  const gameConfigString = localStorage.getItem(localStorageKey);
  if (!gameConfigString) return defaultGameConfig;

  const gameConfig: MinesweeperGameConfig = JSON.parse(gameConfigString);
  const { boardHeight, boardWidth, difficulty } = gameConfig;

  return {
    boardHeight: minesweeperConfig.boardSizeOptions.includes(boardHeight)
      ? boardHeight
      : defaultGameConfig.boardHeight,
    boardWidth: minesweeperConfig.boardSizeOptions.includes(boardWidth)
      ? boardWidth
      : defaultGameConfig.boardWidth,
    // Difficulty requires more parsing/validation because it is an enum
    difficulty: Object.keys(MinesweeperDifficulty).includes(difficulty)
      ? difficulty
      : defaultGameConfig.difficulty,
  };
};

/**
 * Load the user's minesweeper game configuration
 * @param config - User's minesweeper game configuration
 */
export const saveMinesweeperConfig = (config: MinesweeperGameConfig) => {
  localStorage.setItem(localStorageKey, JSON.stringify(config));
};

export default minesweeperConfig;
