





















































import { mdiCog, mdiRefresh } from "@mdi/js";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

// Components
import { ConfirmDialog } from "@components/Dialogs";

// Types
import { GameState } from "@typings/enums";

@Component({
  components: {
    ConfirmDialog,
  },
})
export default class MinesweeperActionBar extends Vue {
  /** Game state */
  @Prop({ required: true })
  gameState!: GameState;

  icons = { mdiCog, mdiRefresh };
  GameState = GameState;
  isResetPromptShown = false;

  /** Whether game is in progress */
  get isPlaying() {
    return (
      this.gameState === GameState.PLAYING ||
      this.gameState === GameState.PAUSED
    );
  }

  @Emit("generate")
  generateGame() {
    // Do nothing
  }

  @Emit("configure")
  configureGame() {
    // Do nothing
  }

  @Emit("reset")
  resetGame() {
    // Do nothing
  }
}
