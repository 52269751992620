




























import { mdiGit, mdiHeart } from "@mdi/js";
import { Component, Vue } from "vue-property-decorator";

// Utilities
import config from "@config";

@Component
export default class TheAppFooter extends Vue {
  icons = { mdiGit, mdiHeart };
  gitUrl = config.gitUrl;
  version = config.version;

  get year() {
    return new Date().getFullYear();
  }
}
